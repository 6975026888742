import axios from 'axios';

const MERCHSTORES_FETCH_GIFT_CARDS_LIST_ROUTE =
  '/.netlify/functions/fetchMerchStoreGiftCardsList';

const giftCardsSummaryCache = new Map<string, IRequestGiftCardsListResult>();

interface IRequestGiftCardsListParameters {
  storeCode: string | undefined | null;
  skip: number | undefined | null;
  limit: number | undefined | null;
}

export interface IRequestGiftCardsListResult {
  success: boolean;
  giftCardsList: any[];
}

export async function requestGiftCardsList(
  parameters: IRequestGiftCardsListParameters
): Promise<IRequestGiftCardsListResult> {
  const response = await axios.post(
    MERCHSTORES_FETCH_GIFT_CARDS_LIST_ROUTE,
    parameters
  );

  return response.data as IRequestGiftCardsListResult;
}

export async function getGiftCardsList(
  parameters: IRequestGiftCardsListParameters
): Promise<IRequestGiftCardsListResult> {
  if (parameters.storeCode) {
    const cachedResult = giftCardsSummaryCache.get(parameters.storeCode);

    if (cachedResult) {
      return cachedResult;
    }
  }

  return requestGiftCardsList(parameters).then((result) => {
    if (parameters.storeCode) {
      giftCardsSummaryCache.set(parameters.storeCode, result);
    }
    return result;
  });
}
