import axios from 'axios';
import { BigNumber } from 'bignumber.js';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { MdArrowBack, MdLaunch } from 'react-icons/md';
import { CTA } from '@merchstores/shared/elements/Cta';
import { FormInput } from '@merchstores/shared/elements/FormInput';
import { Table } from '@merchstores/shared/elements/Table';
import { ProductEditSidebarMenu } from './ProductEditSidebarMenu';
import {
  IMerchStoreProductEditProps,
  IMerchStoreProductInputProps,
  IMerchStoreVariantInputProps,
} from '.';
import {
  IMerchStoreProduct,
  IProductImage,
  IProductVariant,
} from '../MerchStoreProductList';
import _, { isNil } from 'lodash';
import { skuWithoutVariantIdTag } from '../../MerchStoreProduct/InventoryProduct';

import './styles.scss';
import { toast } from 'react-toastify';
import { ProductMedia } from './ProductMedia';
import {
  userHasRole,
  ROLE_SUPERUSER,
  ROLE_MERCHOLOGIST,
  ROLE_GROUPADMIN,
} from '@merchstores/admin/context/Roles';
import { IFormOptionProps } from '@merchstores/shared/elements/FormSelect';

interface IMerchStoreProductEditPermissions {
  viewInventoryLevels: boolean;
}

export const MerchStoreProductEdit: React.FC<IMerchStoreProductEditProps> = (
  props: IMerchStoreProductEditProps
) => {
  const {
    selectedProduct,
    setSelectedProduct,
    onProductPreviewClosed,
    isViewMode = false,
    userRole,
    storeCode,
    fetchCollection,
    deleteProduct,
    artworkOptions,
    disableEdit = false,
  } = props;
  const [product, setProduct] = useState({}) as [IMerchStoreProduct, any];
  const [variantData, setVariantData] = useState([]);
  const [descriptionHtml, setDescriptionHtml] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [selectedDiscount, setSelectedDiscount] = useState(0);
  const [selectedArtwork, setSelectedArtwork] = useState(1);
  const [productImages, setProductImages] = useState([]);
  const [lowestVariantPrice, setLowestVariantPrice] = useState(0);
  const [lowestVariantCompareAt, setLowestVariantCompareAt] = useState(0);
  const [decoType, setDecoType] = useState(undefined);
  const [decoTypeOptions, setDecoTypeOptions] = useState([]);
  const [isDecoTypeReadOnly, setIsDecoTypeReadOnly] = useState(false);
  const [decoLocation, setDecoLocation] = useState(undefined);
  const [decoLocationOptions, setDecoLocationOptions] = useState([]);
  const [isDecoLocationReadOnly, setIsDecoLocationReadOnly] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const isStoreAdmin = userHasRole(userRole, [ROLE_GROUPADMIN]);

  const permissions: IMerchStoreProductEditPermissions = {
    viewInventoryLevels: userHasRole(userRole, [
      ROLE_SUPERUSER,
      ROLE_MERCHOLOGIST,
    ]),
  };

  useEffect(() => {
    if (selectedProduct) {
      setProduct(selectedProduct);
      setDescriptionHtml(selectedProduct.descriptionHtml || '');
      setSelectedArtwork(selectedProduct.artworkSelected);
      setProductImages(selectedProduct.images);
      if (selectedProduct.tags) {
        const decoTypes: IFormOptionProps[] = [];
        const decoLocations: IFormOptionProps[] = [];
        selectedProduct.tags.forEach((tag: string) => {
          const tagSplits = tag.split('=');
          const tagPrefix = tagSplits[0];
          const tagSuffix = tagSplits[1];
          if (tagPrefix === 'deco_type') {
            decoTypes.push({ displayText: tagSuffix, value: tagSuffix });
          } else if (tagPrefix === 'deco_location') {
            decoLocations.push({ displayText: tagSuffix, value: tagSuffix });
          } else if (tagPrefix === 'selected_deco_type') {
            setDecoType(tagSuffix);
            setIsDecoTypeReadOnly(isStoreAdmin);
          } else if (tagPrefix === 'selected_deco_location') {
            setDecoLocation(tagSuffix);
            setIsDecoLocationReadOnly(isStoreAdmin);
          }
        });
        setDecoTypeOptions(decoTypes);
        setDecoLocationOptions(decoLocations);
      }
      reset();
    }
    if (selectedProduct && !_.isEmpty(selectedProduct.variants)) {
      let lowVariantPrice = '';
      let lowVariantCompareAt = '';
      const productVariantData = selectedProduct.variants.map(
        (variant: IProductVariant, index: number) => {
          if (index === 0 || variant.price < lowestVariantPrice) {
            lowVariantPrice = variant.price;
            if (variant.compareAtPrice) {
              lowVariantCompareAt = variant.compareAtPrice.amount;
            }
          }
          const data: any = {};

          data.titleSku = {
            desktopOnly: false,
            value: (
              <div className="flex ml-4 lg:ml-6 items-center">
                <div className="text-left font-bold text-merch-dark-gray w-70 mr-3">
                  {variant.title}
                </div>
                <div className="hidden lg:block text-right pl-3 text-merch-dark-gray overflow-x-hidden">
                  {skuWithoutVariantIdTag(variant.sku)}
                </div>
              </div>
            ),
          };
          data.inventoryCost = {
            desktopOnly: false,
            value: (
              <div className="flex justify-end mr-4 lg:mr-6 align-center">
                {!isViewMode && permissions.viewInventoryLevels && (
                  <div className="text-right pl-3 text-merch-dark-gray">
                    {`${variant.quantityAvailable} available`}
                  </div>
                )}
                <div className="text-right pl-7 text-merch-dark-gray">
                  {`$${variant.price}`}
                </div>
              </div>
            ),
          };

          return {
            tr: {
              class: '',
              data,
            },
          };
        }
      );
      const getDiscountPercentage = () => {
        if (!lowVariantCompareAt) {
          return 0;
        } else {
          const currentPrice = new BigNumber(lowVariantPrice);
          const originalPrice = new BigNumber(lowVariantCompareAt);
          const base = new BigNumber(100);
          return parseInt(
            base
              .minus(currentPrice.dividedBy(originalPrice).times(base))
              .toFormat(0)
          );
        }
      };
      const discount = getDiscountPercentage();
      setLowestVariantPrice(lowVariantPrice);
      setLowestVariantCompareAt(
        !lowVariantCompareAt ? lowVariantPrice : lowVariantCompareAt
      );
      setDiscountPercentage(discount);
      setSelectedDiscount(discount);
      setVariantData(productVariantData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, lowestVariantPrice, reset, selectedProduct]);

  const handleProductSave = (data: any) => {
    if (disableEdit) {
      toast.error('Please submit active orders before editing products');
      return;
    }

    const productInput: IMerchStoreProductInputProps = {
      id: product.id,
    };
    productInput.tags = selectedProduct.tags;
    let shouldUpdate = false;

    if (data['product-title'] && data['product-title'] !== product.title) {
      productInput.title = data['product-title'];
      productInput.handle = data['product-title']
        .replace(/[^a-zA-Z0-9- ]/g, '')
        .replace(/[ ]/g, '-')
        .concat(`-${storeCode}`)
        .toLowerCase();
      shouldUpdate = true;
    }

    if (descriptionHtml !== product.descriptionHtml) {
      productInput.descriptionHtml = descriptionHtml;
      shouldUpdate = true;
    }

    if (selectedDiscount !== discountPercentage) {
      const getFormattedPrice = (variant: IProductVariant) => {
        const discountPercentage = (100 - selectedDiscount) / 100;
        const originalPrice = new BigNumber(
          variant.compareAtPrice ? variant.compareAtPrice.amount : variant.price
        );
        const price = originalPrice.times(discountPercentage);
        return price.toFixed(2);
      };
      const updatedVariants = product.variants.map(
        (variant: IProductVariant) => {
          return {
            id: variant.id,
            price:
              selectedDiscount === 0
                ? variant.compareAtPrice?.amount
                : getFormattedPrice(variant),
            compareAtPrice: variant.compareAtPrice
              ? variant.compareAtPrice.amount
              : variant.price,
          };
        }
      ) as [IMerchStoreVariantInputProps];
      productInput.variants = updatedVariants;
      shouldUpdate = true;
    }

    if (selectedArtwork !== product.artworkSelected) {
      const productTags = productInput.tags.filter(
        (tag: string) => !tag.includes('artwork_option=')
      );
      productTags.push(`artwork_option=${selectedArtwork}`);
      productInput.tags = productTags;
      shouldUpdate = true;
    }

    if (productImages.length !== product.images.length) {
      productInput.images = productImages;
      shouldUpdate = true;
    } else {
      product.images.forEach((image: IProductImage) => {
        if (
          !productImages
            .map((image: IProductImage) => image.src)
            .includes(image.src)
        ) {
          productInput.images = productImages;
          shouldUpdate = true;
        }
      });
    }

    if (
      data['product-decoration-type'] != '' &&
      data['product-decoration-type'] !== decoType
    ) {
      const productTags = productInput.tags.filter(
        (tag: string) => !tag.includes('selected_deco_type=')
      );
      productTags.push(`selected_deco_type=${data['product-decoration-type']}`);
      productInput.tags = productTags;
      shouldUpdate = true;
    }

    if (
      data['product-decoration-location'] != '' &&
      data['product-decoration-location'] !== decoType
    ) {
      const productTags = productInput.tags.filter(
        (tag: string) => !tag.includes('selected_deco_location=')
      );
      productTags.push(
        `selected_deco_location=${data['product-decoration-location']}`
      );
      productInput.tags = productTags;
      shouldUpdate = true;
    }

    if (shouldUpdate) {
      axios
        .post('/.netlify/functions/updateProduct', {
          productInput: productInput,
        })
        .then(() => {
          toast.success('Product updated — your changes will appear shortly.');
          setSelectedProduct(null);
          fetchCollection(storeCode, true);
        })
        .catch((err) => {
          console.error(err);
          toast.error('Error updating product');
        });
    }
  };

  const handleProductTagUpdate = (artworkSelected: number) => {
    const productInput: IMerchStoreProductInputProps = {
      id: product.id,
    };
    if (artworkSelected !== product.artworkSelected) {
      const productTags = selectedProduct.tags.filter(
        (tag: string) => !tag.includes('artwork_option=')
      );
      productTags.push(`artwork_option=${artworkSelected}`);
      productInput.tags = productTags;
      axios
        .post('/.netlify/functions/updateProduct', {
          productInput: productInput,
        })
        .then(() => {
          toast.success('Product logo updated successfully');
          fetchCollection(storeCode);
          const productUpdate = product;
          productUpdate.artworkSelected = artworkSelected;
          setProduct(productUpdate);
        })
        .catch((err) => {
          console.error(err);
          toast.error('Error updating product');
        });
    }
  };

  const handleGoBack = () => {
    if (!isViewMode) {
      setSelectedProduct(null);
    }

    if (isViewMode && !isNil(onProductPreviewClosed)) {
      onProductPreviewClosed();
    }
  };

  const onArtworkSelectedChanged = (artworkSelected: number) => {
    setSelectedArtwork(artworkSelected);
    if (isStoreAdmin) {
      handleProductTagUpdate(artworkSelected);
    }
  };

  const onProductImagesChanged = (images: IProductImage[]) => {
    setProductImages(images);
  };

  const selectedProductTitle = !isNil(selectedProduct) && selectedProduct.title;

  return (
    <form
      onSubmit={handleSubmit(handleProductSave)}
      className="w-full"
      id="create-edit-product-form"
    >
      <div className="lg:flex h-120">
        <div
          className={`${
            isViewMode ? 'w-full p-15 md:p-15' : 'w-full p-20 md:p-30'
          }`}
        >
          <div className="w-full flex justify-between mb-3 lg:mb-3">
            <div className="flex flex-col lg:flex-row lg:items-center">
              <CTA size="small" type="mobile-icon" onClick={handleGoBack}>
                <MdArrowBack />
              </CTA>
              <div className="mt-3 lg:ml-6 lg:mt-0 flex font-bold text-xl">
                {selectedProductTitle}
              </div>
            </div>
            {!isViewMode && (
              <CTA size="small" type="mobile-icon">
                <MdLaunch />
              </CTA>
            )}
          </div>
          <div
            className={`view-edit-product-container w-full mt-9 ${
              isViewMode ? ' scrollable' : ''
            }`}
          >
            <div
              // eslint-disable-next-line max-len
              className="flex flex-col w-full bg-merch-background-gray border border-gray-200 rounded-lg pl-4 pr-4 pt-4 mt-6"
            >
              <FormInput
                name="product-title"
                disabled={isStoreAdmin || isViewMode}
                defaultValue={product.title}
                placeholder={product.title}
                type="text"
                required={!isStoreAdmin}
                register={register}
                errors={errors}
                label="Title"
                inputStyle="two"
                className="opacity-100"
              />
              {
                <ReactQuill
                  id="product-edit-description"
                  style={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px',
                    marginBottom: '20px',
                  }}
                  readOnly={isStoreAdmin || isViewMode}
                  theme="snow"
                  value={descriptionHtml}
                  onChange={setDescriptionHtml}
                />
              }
            </div>

            <ProductMedia
              product={product}
              artworkOptions={artworkOptions}
              onArtworkSelectedChanged={onArtworkSelectedChanged}
              onProductImagesChanged={onProductImagesChanged}
              isViewMode={isViewMode}
            />

            <div className="flex flex-col w-full bg-merch-background-gray border border-gray-200 rounded-lg p-4 mt-6">
              <div className="text-lg font-bold mb-2">Variants</div>
              <div className="variant-table-container">
                <Table
                  itemsName="variants"
                  name="variants"
                  filter={true}
                  data={variantData}
                  elementsPerPage={50}
                  displayFooter={false}
                />
              </div>
            </div>
          </div>
        </div>
        {!isViewMode && (
          <ProductEditSidebarMenu
            product={product}
            register={register}
            errors={errors}
            lowestVariantPrice={lowestVariantPrice}
            lowestVariantCompareAt={lowestVariantCompareAt}
            userRole={userRole}
            selectedDiscount={selectedDiscount}
            setSelectedDiscount={setSelectedDiscount}
            setSelectedProduct={setSelectedProduct}
            deleteProduct={deleteProduct}
            setValue={setValue}
            decoType={decoType}
            isDecoTypeReadOnly={isDecoTypeReadOnly}
            decoTypeOptions={decoTypeOptions}
            decoLocation={decoLocation}
            isDecoLocationReadOnly={isDecoLocationReadOnly}
            decoLocationOptions={decoLocationOptions}
          />
        )}
      </div>
    </form>
  );
};
