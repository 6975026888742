import axios from 'axios';

import { IMerchStoreIndividualOrder } from '@merchstores/admin/types/merchstoreOrder';

const MERCHSTORES_FETCH_ORDERS_LIST_ROUTE =
  '/.netlify/functions/fetchMerchStoreOrdersList';

interface IRequestOrdersListParameters {
  month: number | null;
  year: number | null;
  skip: number | undefined | null;
  limit: number | undefined | null;
}

export interface IRequestOrdersListResult {
  success: boolean;
  ordersList: IMerchStoreIndividualOrder[];
}

export async function getOrdersList(
  parameters: IRequestOrdersListParameters
): Promise<IRequestOrdersListResult> {
  const response = await axios.post(
    MERCHSTORES_FETCH_ORDERS_LIST_ROUTE,
    parameters
  );

  return response.data as IRequestOrdersListResult;
}
