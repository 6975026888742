import axios from 'axios';

import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { Context } from '../../context';
import { ReassignMerch } from '../ReassignMerch/component';
import { Badge } from '@merchstores/shared/elements/Badge';
import { Card } from '@merchstores/shared/elements/Card';
import { Logo } from '@merchstores/shared/elements/Logo';
import { Progress } from '@merchstores/shared/elements/Progress';
import { ActionDropdown } from '@merchstores/shared/elements/ActionDropdown';
import { FilterIndicator } from '@merchstores/shared/elements/FilterIndicator';
import { FilterDropdown } from '@merchstores/shared/elements/FilterDropdown';
import { Table } from '@merchstores/shared/elements/Table';
import { MultiDropdown } from '@merchstores/admin/shared/elements/MultiDropdown';
import { SortDropdown } from '@merchstores/shared/elements/SortDropdown';
import './styles.scss';
import { cloudinaryApplyWidthHeight } from '@merchstores/shared/components/Cloudinary';
import { AdminHeader } from '../AdminHeader';
import { CTA } from '@merchstores/shared/elements/Cta';
import { MdGroupAdd } from 'react-icons/md';
import ClosedOrderDropdown from './ClosedOrderDropdown';
import { getLocalDateText } from '../DateTime/DateTimeFormat';
import { triggerCloseOrderAction } from './CloseOrderAction';
import { exportGOTRecipientsToNetsuite } from '../Netsuite/GroupOrderToolRecipients';
import { Loading } from '@merchstores/shared/components/Loading';
import { toast } from 'react-toastify';
import { Footer } from '@merchstores/shared/elements/Footer';
import { SearchBox } from '@merchstores/shared/elements/SearchBox';

const ORDERS_PER_PAGE = 50;

const buildGroupOrderDropdownOptions = (
  groupOrder: {
    groupOrderId: string;
    status: string;
    merchologist: string;
    membersSubmissionCount: number;
  },
  userRole: string,
  userEmail: string,
  history: History,
  fetchGroupOrdersFn: () => any,
  onReassignMerchologist: () => any
): any[] => {
  const viewEditOption = {
    title: 'View/Edit',
    id: `${groupOrder.groupOrderId}-view`,
    action: () => {
      history.push(`/admin/group-orders/${groupOrder.groupOrderId}`);
    },
  };

  const reassignMerchologistOption = {
    title: 'Reassign',
    id: `${groupOrder.groupOrderId}-reassign`,
    action: onReassignMerchologist,
  };

  const closeOption = {
    title: 'Close',
    id: `${groupOrder.groupOrderId}-close`,
    action: () => {
      triggerCloseOrderAction(groupOrder.groupOrderId).then(() => {
        groupOrder.status = 'closed';
        exportGOTRecipientsToNetsuite(groupOrder.groupOrderId, '');
        // orders model has some issues that prevents correct re-render
        // on update so we fetch and rebuild it again
        fetchGroupOrdersFn();
      });
    },
  };

  const downloadCSVOption = {
    title: 'Download CSV',
    id: `${groupOrder.groupOrderId}-download-csv`,
    action: () => {
      toast.success(`Downloading recipients csv...`);
      window.open(
        `/.netlify/functions/downloadRecipientsCsv?orderId=${groupOrder.groupOrderId}`
      );
    },
  };

  if (['MERCHOLOGIST', 'ADMIN'].includes(userRole)) {
    let options = [viewEditOption, closeOption];
    if (groupOrder.membersSubmissionCount > 0) {
      options = [viewEditOption, downloadCSVOption, closeOption];
    }
    return options;
  }

  if (['SUPERUSER'].includes(userRole)) {
    let options = [viewEditOption, reassignMerchologistOption, closeOption];
    if (groupOrder.membersSubmissionCount > 0) {
      options = [
        viewEditOption,
        downloadCSVOption,
        reassignMerchologistOption,
        closeOption,
      ];
    }
    return options;
  }

  return [viewEditOption];
};

export const GroupOrders = (_props: any) => {
  // Define the actual props needed in the index file

  const { userRole, userEmail } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [groupOrders, setGroupOrders] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const [orderId, setOrderId] = useState('');
  const [currentMerch, setCurrentMerch] = useState('');
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const [statusFilterOptions, setStatusFilterOptions] = useState([]);
  const [adminFilterOptions, setAdminFilterOptions] = useState([]);
  const [assignedFilterOptions, setAssignedFilterOptions] = useState([]);

  const [statusFilterChosen, setStatusFilterChosen] = useState('');
  const [adminFilterChosen, setAdminFilterChosen] = useState('');
  const [assignedFilterChosen, setAssignedFilterChosen] = useState('');
  const [sortOptionChosen, setSortOptionChosen] = useState('dateDesc');
  const [searchTerms, setSearchTerms] = useState('');
  const [columnPreferences, setColumnPreferences] = useState([]);

  const iconSize = isMobile ? '18px' : '22px';

  const onSearchAction = (searchTerms: string): void => {
    setSearchTerms(searchTerms);
    setTableLoading(true);
  };

  const onPagination = async (pageNumber: number) => {
    // For legacy compatibility, pageNumber starts on 0
    return fetchGroupOrders(pageNumber * ORDERS_PER_PAGE);
  };

  const fetchGroupOrders = async (pageOffset?: number) => {
    const fetchResult = await axios
      .post('/.netlify/functions/fetchGroupOrders', {
        search: searchTerms,
        sorting: sortOptionChosen,
        email: userEmail,
        role: userRole,
        status: statusFilterChosen,
        limit: ORDERS_PER_PAGE,
        groupAdmin: adminFilterChosen,
        merchologist: assignedFilterChosen,
        offset: pageOffset ? pageOffset : 0,
      })
      .catch((err) => {
        console.error(err);
        return null;
      });

    if (!fetchResult) {
      return {
        groupOrders: [],
      };
    }

    setHasNextPage(fetchResult.data.hasNextPage);
    setTotalCount(fetchResult.data.totalCount);

    const groupOrders = fetchResult.data.groupOrders.map((groupOrder: any) => {
      const membersTotalCount = groupOrder?.membersTotalCount;
      const currentSubmissions = groupOrder?.membersSubmissionCount;
      const invitedMembers = groupOrder?.membersInvitedCount;

      const onReassignMerchologist = () => {
        onOpenModal();
        setOrderId(groupOrder.groupOrderId);
        setCurrentMerch(groupOrder.merchologist);
      };

      let dropDownOptions: any[] = buildGroupOrderDropdownOptions(
        groupOrder,
        userRole,
        userEmail,
        history,
        fetchGroupOrders,
        onReassignMerchologist
      );

      // takes the existing default menu and replaces with a new one if the order
      // is closed
      dropDownOptions = ClosedOrderDropdown.resolveDropdownOptions(
        groupOrder,
        userRole,
        userEmail,
        history,
        dropDownOptions
      );

      const data: any = {};
      const optimizedLogo = cloudinaryApplyWidthHeight(
        groupOrder.storeLogo,
        '72',
        '72'
      );
      const colPrefs = columnPreferences.map((pref: any) => pref.id);

      data.logo = {
        desktopOnly: false,
        value: (
          <a
            href={`/admin/group-orders/${groupOrder.groupOrderId}`}
            className=""
          >
            <Logo size="small" imgUrl={optimizedLogo} />
            <div className="text-left pl-3 font-bold text-merch-dark-gray pt-3 w-200">
              {groupOrder.name}
            </div>
          </a>
        ),
      };

      if (userRole !== 'ADMIN' && colPrefs.includes('customerContact')) {
        data.groupAdmin = {
          desktopOnly: true,
          value: (
            <div className="text-left pl-10 font-bold text-merch-dark-gray text-opacity-50">
              {groupOrder.groupAdmin}
            </div>
          ),
        };
      }

      if (
        userRole !== 'MERCHOLOGIST' &&
        userRole !== 'ADMIN' &&
        colPrefs.includes('merchologyContact')
      ) {
        data.assignedTo = {
          desktopOnly: true,
          value: (
            <div className="text-left font-bold text-merch-dark-gray text-opacity-50">
              {groupOrder.merchologist}
            </div>
          ),
        };
      }

      if (colPrefs.includes('orderId')) {
        data.groupOrderId = {
          desktopOnly: true,
          value: (
            <div className="text-center font-bold text-merch-dark-gray text-opacity-50">
              {groupOrder.groupOrderId}
            </div>
          ),
        };
      }

      if (colPrefs.includes('openDate')) {
        data.openDate = {
          desktopOnly: true,
          value: (
            <div className="font-bold text-merch-dark-gray">
              {getLocalDateText(groupOrder.openDate)}
            </div>
          ),
        };
      }

      if (colPrefs.includes('closeDate')) {
        data.closeDate = {
          desktopOnly: true,
          value: (
            <div className="font-bold text-merch-dark-gray">
              {getLocalDateText(groupOrder.closeDate)}
            </div>
          ),
        };
      }

      if (colPrefs.includes('submissions')) {
        data.progress = {
          desktopOnly: false,
          value:
            invitedMembers.length === 0 ? (
              <div className="font-bold text-merch-dark-gray text-13">
                {currentSubmissions}
              </div>
            ) : (
              <Progress
                progress={{
                  total: membersTotalCount,
                  value: currentSubmissions,
                }}
                bgcolor="#FF671D"
              />
            ),
        };
      }

      if (colPrefs.includes('status')) {
        data.status = {
          desktopOnly: false,
          value: (
            <Badge
              className="no-text-mobile"
              type={groupOrder.status.toLowerCase()}
            >
              {groupOrder.status}
            </Badge>
          ),
        };
      }
      data.actions = {
        desktopOnly: false,
        value: <ActionDropdown title="Actions" list={dropDownOptions} />,
      };

      return {
        tr: {
          class: `${groupOrder.status.toLowerCase()}`,
          data,
        },
      };
    });
    /** 'Customer Contact' filter options */
    const adminFilterArray = () => {
      let admins = fetchResult.data.groupAdmins;

      admins = admins
        .sort((a: string, b: string) => a.localeCompare(b))
        .filter(Boolean);

      const customerContactOptions = admins.map((groupAdmin: string) => {
        return {
          title: `${groupAdmin}`,
          action: () => {
            setTableLoading(true);
            setAdminFilterChosen(groupAdmin);
          },
        };
      });

      setAdminFilterOptions(customerContactOptions);
    };

    /**  Only look for "Customer Contact" filter options if user is a super user */
    userRole !== 'ADMIN' && adminFilterArray();

    /**  'Merchology Contact' filter options */
    const assignedFilterArray = () => {
      let merchologists = fetchResult.data.merchologists;

      merchologists = merchologists
        .sort((a: string, b: string) => a.localeCompare(b))
        .filter(Boolean);

      const merchologistOptions = merchologists.map((merchologist: string) => {
        return {
          title: `${merchologist}`,
          action: () => {
            setTableLoading(true);
            setAssignedFilterChosen(merchologist);
          },
        };
      });

      setAssignedFilterOptions(merchologistOptions);
    };

    /**  Only look for "Merchology Contact" filter options if user is a super user */
    userRole !== 'MERCHOLOGIST' &&
      userRole !== 'ADMIN' &&
      assignedFilterArray();

    setGroupOrders(groupOrders);

    if (groupOrders) {
      setTimeout(() => setLoading(false), 500);
    }
    if (groupOrders) {
      setTimeout(() => setTableLoading(false), 60);
    }

    return {
      groupOrders: groupOrders,
    };
  };

  useEffect(() => {
    if (userRole && userEmail) {
      fetchGroupOrders();
    }
    const isMobile = window.matchMedia(
      'only screen and (max-width: 760px)'
    ).matches;
    setIsMobile(isMobile);
  }, [
    searchTerms,
    sortOptionChosen,
    statusFilterChosen,
    adminFilterChosen,
    assignedFilterChosen,
    userRole,
    currentMerch,
    history,
    userEmail,
    columnPreferences,
  ]);

  useEffect(() => {
    /** 'Status' filter options */
    setStatusFilterOptions([
      {
        title: 'Active',
        id: 'statusFilterActive',
        action: () => {
          setTableLoading(true);
          setStatusFilterChosen('active');
        },
      },
      {
        title: 'Draft',
        id: 'statusFilterDraft',
        action: () => {
          setTableLoading(true);
          setStatusFilterChosen('draft');
        },
      },
      {
        title: 'Closed',
        id: 'statusFilterClosed',
        action: () => {
          setTableLoading(true);
          setStatusFilterChosen('closed');
        },
      },
    ]);
  }, [groupOrders, userRole]);

  useEffect(() => {
    const localColumnPrefs = window.localStorage.getItem(
      'columnPreferencesGOT'
    );
    if (localColumnPrefs !== null) {
      const localColumnPrefsArr = JSON.parse(localColumnPrefs);
      setColumnPreferences([...localColumnPrefsArr]);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem(
      'columnPreferencesGOT',
      JSON.stringify(columnPreferences)
    );
  }, [columnPreferences]); // end useEffect

  const sortOptions = [
    {
      title: isMobile ? 'Order A - Z' : 'Order Name A - Z',
      id: 'name_a-z',
      action: () => {
        setSortOptionChosen('nameDesc');
      },
    },
    {
      title: isMobile ? 'Order Z - A' : 'Order Name Z - A',
      id: 'name_z-a',
      action: () => {
        setSortOptionChosen('nameAsc');
      },
    },
    {
      title: isMobile
        ? 'Creation Date (Oldest)'
        : 'Creation Date (Oldest First)',
      id: 'creation_date_oldest',
      action: () => {
        setSortOptionChosen('creationDateAsc');
      },
    },
    {
      title: isMobile
        ? 'Creation Date (Newest)'
        : 'Creation Date (Newest First)',
      id: 'creation_date_newest',
      action: () => {
        setSortOptionChosen('creationDateDesc');
      },
    },
    {
      title: isMobile ? 'Open Date (Oldest)' : 'Open Date (Oldest First)',
      id: 'date_oldest',
      action: () => {
        setSortOptionChosen('dateAsc');
      },
    },
    {
      title: isMobile ? 'Open Date (Newest)' : 'Open Date (Newest First)',
      id: 'date_newest',
      action: () => {
        setSortOptionChosen('dateDesc');
      },
    },
    {
      title: isMobile ? 'Close Date (Oldest)' : 'Close Date (Oldest First)',
      id: 'close_date_oldest',
      action: () => {
        setSortOptionChosen('closeDateAsc');
      },
    },
    {
      title: isMobile ? 'Close Date (Newest)' : 'Close Date (Newest First)',
      id: 'close_date_newest',
      action: () => {
        setSortOptionChosen('closeDateDesc');
      },
    },
    {
      title: isMobile ? 'Submissions (Fewest)' : 'Submissions (Fewest First)',
      id: 'submissions_fewest',
      action: () => {
        setSortOptionChosen('submissionsAsc');
      },
    },
    {
      title: isMobile ? 'Submissions (Most)' : 'Submissions (Most First)',
      id: 'submissions_most',
      action: () => {
        setSortOptionChosen('submissionsDesc');
      },
    },
  ];

  let columns: any[] = [];

  switch (userRole) {
    case 'MERCHOLOGIST':
      columns = [
        {
          text: 'Group Order',
          //title: 'Group Order',
          class: 'text-left',
          //id: 'groupOrder',
        },
        {
          text: 'Customer Contact',
          title: 'Customer Contact',
          class: 'text-left pl-10',
          id: 'customerContact',
        },
        {
          text: 'Order ID',
          title: 'Order ID',
          id: 'orderId',
        },
        {
          text: 'Open',
          title: 'Open',
          id: 'open',
        },
        {
          text: 'Close',
          title: 'Close',
          id: 'close',
        },
        {
          text: 'Submissions',
          title: 'Submissions',
          id: 'submissions',
        },
        {
          text: 'Status',
          title: 'Status',
          id: 'status',
        },
      ];
      break;
    case 'ADMIN':
      columns = [
        {
          text: 'Group Order',
          //title: 'Group Order',
          class: 'text-left',
          //id: 'groupOrder',
        },
        {
          text: 'Order ID',
          title: 'Order ID',
          id: 'orderId',
        },
        {
          text: 'Open',
          title: 'Open',
          id: 'open',
        },
        {
          text: 'Close',
          title: 'Close',
          id: 'close',
        },
        {
          text: 'Submissions',
          title: 'Submissions',
          id: 'submissions',
        },
        {
          text: 'Status',
          title: 'Status',
          id: 'status',
        },
      ];
      break;
    default:
      columns = [
        {
          text: 'Group Order',
          //title: 'Group Order',
          class: 'text-left',
          //id: 'groupOrder',
        },
        {
          text: 'Customer Contact',
          title: 'Customer Contact',
          class: 'text-left pl-10',
          id: 'customerContact',
        },
        {
          text: 'Merchology Contact',
          title: 'Merchology Contact',
          class: 'text-left',
          id: 'merchologyContact',
        },
        {
          text: 'Order ID',
          title: 'Order ID',
          class: 'text-center',
          id: 'orderId',
        },
        {
          text: 'Open Date',
          title: 'Open Date',
          id: 'openDate',
        },
        {
          text: 'Close Date',
          title: 'Close Date',
          id: 'closeDate',
        },
        {
          text: 'Submissions',
          title: 'Submissions',
          id: 'submissions',
        },
        {
          text: 'Status',
          title: 'Status',
          id: 'status',
        },
      ];
      break;
  }

  if (!columnPreferences.length) {
    setColumnPreferences(columns);
  }

  const columnHeaders = columnPreferences
    .filter((preference: any) => {
      return columns.some((column) => {
        return column.id === preference.id || column.id == null;
      });
    })
    .sort((a: any, b: any) => {
      const indexA = columns.findIndex((column) => column.text === a.text);
      const indexB = columns.findIndex((column) => column.text === b.text);
      return indexA - indexB;
    });

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => setOpen(false);

  const removeFilter = (clickedFilter: string) => {
    setTableLoading(true);

    if (clickedFilter === 'status') {
      setStatusFilterChosen('');
    } else if (clickedFilter === 'admin') {
      setAdminFilterChosen('');
    } else if (clickedFilter === 'assignedTo') {
      setAssignedFilterChosen('');
    }
  };

  if (loading) return <Loading isLoading={true} />;

  return (
    <>
      <AdminHeader title="Group Orders" subtitle={new Date().toDateString()}>
        {userRole !== 'ADMIN' && (
          <div>
            <CTA
              disabled={false}
              size="standard"
              type="primary"
              icon={<MdGroupAdd size={iconSize} />}
              onClick={() => history.push('/admin/group-orders/new')}
              mobileIconView={true}
            >
              {'New Group Order'}
            </CTA>
          </div>
        )}
      </AdminHeader>
      <div className="flex justify-between dropdowns-container">
        <div className="filter-dropdowns">
          <FilterDropdown
            title="Status"
            list={statusFilterOptions}
            chosenFilter={statusFilterChosen}
            btnClass="status-orders-page"
          />
          {userRole !== 'ADMIN' && (
            <FilterDropdown
              title="Customer Contact"
              list={adminFilterOptions}
              chosenFilter={adminFilterChosen}
              searchable={true}
              btnClass={
                userRole === 'SUPERUSER'
                  ? 'super-admin-orders'
                  : 'admin-orders-page'
              }
            />
          )}
          {userRole !== 'MERCHOLOGIST' && userRole !== 'ADMIN' && (
            <FilterDropdown
              title="Merchology Contact"
              list={assignedFilterOptions}
              chosenFilter={assignedFilterChosen}
              searchable={true}
              btnClass="assigned-orders-page"
            />
          )}
        </div>

        <div className="flex search-box-container ml-4 w-1/4">
          <SearchBox onSearch={onSearchAction} />
        </div>

        <div className="sort-orders-container ml-auto">
          <SortDropdown
            title="Sort"
            list={sortOptions}
            defaultSort="date_newest"
          />
          <MultiDropdown
            title="Columns"
            subTitle="Show/Hide Columns"
            className="ml-2"
            list={columns}
            checkedItems={columnPreferences}
            setCheckedItems={setColumnPreferences}
          />
        </div>
      </div>
      <div className="filters-chosen-container">
        {statusFilterChosen && (
          <FilterIndicator
            title={statusFilterChosen}
            onRemove={() => removeFilter('status')}
          />
        )}
        {adminFilterChosen && (
          <FilterIndicator
            title={adminFilterChosen}
            onRemove={() => removeFilter('admin')}
          />
        )}
        {assignedFilterChosen && (
          <FilterIndicator
            title={assignedFilterChosen}
            onRemove={() => removeFilter('assignedTo')}
          />
        )}
      </div>
      <div className="group-orders-container">
        <Card data-class="group-orders">
          {tableLoading ? (
            <Loading isLoading={true} width="75px" />
          ) : (
            <Table
              itemsName="orders"
              name="group-orders"
              filter={true}
              columns={columnHeaders}
              data={groupOrders}
              elementsPerPage={ORDERS_PER_PAGE}
              hasNextPage={hasNextPage}
              externalPagination={true}
              onPagination={onPagination}
              totalCount={totalCount}
            />
          )}
        </Card>
        <Modal
          open={open}
          onClose={onCloseModal}
          center
          classNames={{ modal: 'reassign-merch-modal' }}
        >
          <ReassignMerch
            currentMerch={currentMerch}
            setCurrentMerch={setCurrentMerch}
            currentOrderId={orderId}
            open={open}
            setOpen={setOpen}
          />
        </Modal>
      </div>
      <Footer />
    </>
  );
};
