import { useContext } from 'react';

import { AdminHeader } from '../AdminHeader';
import { Card } from '@merchstores/shared/elements/Card';
import { Context } from '../../context';
import GiftCardCSVDownloadButton from '../GiftCardCSVDownloadButton';
import OrdersCSVDownloadMenu from '../OrdersCSVDownloadMenu';
import {
  userHasRole,
  Role,
  ROLE_SUPERUSER,
} from '@merchstores/admin/context/Roles';

export function MerchStoreTools(): JSX.Element {
  const { userRole } = useContext(Context);
  const isSuperUser = userHasRole(new Role(userRole), [ROLE_SUPERUSER]);
  return (
    <>
      <AdminHeader title="Merchstore Tools" subtitle={'Internal'}></AdminHeader>
      <div className="flex justify-center">
        <Card>
          <div className="flex justify-center items-center w-full flex-col p-4 lg:flex-row">
            {isSuperUser && (
              <div className="flex flex-col items-center justify-center m-4 p-4 border-2 rounded-md">
                <div className="text-xl font-bold">Gift Cards Report</div>
                <div className="text-md">Download a CSV of all gift cards.</div>
                <GiftCardCSVDownloadButton className="m-2" />
              </div>
            )}
            {isSuperUser && (
              <div className="flex flex-col items-center justify-center m-4 p-4 border-2 rounded-md">
                <div className="text-xl font-bold">Orders Report</div>
                <div className="text-md">
                  Download a CSV of all orders created in a month/year.
                </div>
                <OrdersCSVDownloadMenu className="m-2" />
              </div>
            )}
          </div>
        </Card>
      </div>
    </>
  );
}
