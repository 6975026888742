import axios from 'axios';
import { IMerchStoreGiftCardsStatus } from '@merchstores/admin/types/merchstore';
import {
  IAssignMemberGiftCardsParameters,
  IReassignMemberGiftCardParameters,
  IUnassignMemberGiftCardsParameters,
  IGetMemberGiftCardsParameters,
  IAssignMemberGiftCardsResult,
  IReassignMemberGiftCardResult,
  IUnassignMemberGiftCardsResult,
  IGetMemberGiftCardsResult,
} from '@merchstores/admin/types/giftCards';
import {
  MERCHSTORES_ASSIGN_MEMBER_GIFT_CARDS,
  MERCHSTORES_REASSIGN_MEMBER_GIFT_CARDS,
  MERCHSTORES_UNASSIGN_MEMBER_GIFT_CARDS,
  GET_GIFT_CARDS_STATUS,
  MERCHSTORES_GET_MEMBER_GIFT_CARDS,
  MERCHSTORES_DISABLE_MEMBER_GIFT_CARDS,
} from '../routes';
import { invalidateGiftCardsSummaryCache } from '@merchstores/admin/components/MerchStoreGiftCards/GiftCardsSummary';

const assignMerchStoreMemberGiftCards = async (
  parameters: IAssignMemberGiftCardsParameters
): Promise<IAssignMemberGiftCardsResult> => {
  invalidateGiftCardsSummaryCache(parameters.storeCode);
  let results: IAssignMemberGiftCardsResult =
    {} as IAssignMemberGiftCardsResult;

  const response = await axios.post(MERCHSTORES_ASSIGN_MEMBER_GIFT_CARDS, {
    storeCode: parameters.storeCode,
    memberEmails: parameters.memberEmails,
    cardValue: parameters.cardValue,
  });
  if (Object.keys(results).length > 0) {
    results.assignedMemberGiftCards = response.data.assignedMemberGiftCards;
    results.existingMemberGiftCards = response.data.existingMemberGiftCards;
  } else {
    results = response.data;
  }

  return results as IAssignMemberGiftCardsResult;
};

const reassignMerchStoreMemberGiftCard = async (
  parameters: IReassignMemberGiftCardParameters
): Promise<IReassignMemberGiftCardResult> => {
  const response = await axios.post(
    MERCHSTORES_REASSIGN_MEMBER_GIFT_CARDS,
    parameters
  );
  return response.data as IReassignMemberGiftCardResult;
};

const unassignMerchStoreMemberGiftCards = async (
  parameters: IUnassignMemberGiftCardsParameters
): Promise<IUnassignMemberGiftCardsResult> => {
  invalidateGiftCardsSummaryCache(parameters.storeCode);
  const response = await axios.post(
    MERCHSTORES_UNASSIGN_MEMBER_GIFT_CARDS,
    parameters
  );
  return response.data as IUnassignMemberGiftCardsResult;
};

const getMemberGiftCards = async (
  parameters: IGetMemberGiftCardsParameters
): Promise<IGetMemberGiftCardsResult> => {
  const response = await axios.post(
    MERCHSTORES_GET_MEMBER_GIFT_CARDS,
    parameters
  );
  return response.data as IGetMemberGiftCardsResult;
};

const getMerchStoreGiftCardsStatus = async (
  storeCode: string
): Promise<IMerchStoreGiftCardsStatus> => {
  const { data } = await axios.post(GET_GIFT_CARDS_STATUS, {
    storeCode: storeCode,
  });
  const statusData = data.giftCardsStatus || {};
  const remainingCards = statusData.remainingCards || 0;
  const currentCardVariantId = statusData.currentCardVariantId
    ? String(statusData.currentCardVariantId).replace(
        'gid://shopify/ProductVariant/',
        ''
      )
    : null;
  return {
    enabled: statusData.enabled || false,
    currentCardValue: statusData.currentCardValue || '0.00',
    currentCardVariantId: currentCardVariantId,
    remainingCards: remainingCards,
    hasPreviousGiftCardValue:
      Boolean(!currentCardVariantId) ||
      Boolean(currentCardVariantId && !remainingCards),
    assignFromMemberListOnly: statusData.assignFromMemberListOnly ?? true,
    assignToMemberListFromStore:
      statusData.assignToMemberListFromStore ?? false,
    assignToNonMemberFromStore: statusData.assignToNonMemberFromStore ?? false,
    preApprovedPaymentMethod: statusData.preApprovedPaymentMethod ?? false,
    preApprovedPaymentMethodLongTerm:
      statusData.preApprovedPaymentMethodLongTerm ?? false,
  };
};

const disableMemberGiftCards = async (ids: string[]): Promise<any[]> => {
  try {
    const promises = ids.map((id) =>
      axios.post(MERCHSTORES_DISABLE_MEMBER_GIFT_CARDS, id)
    );
    const responses = await Promise.all(promises);
    return responses.map((response) => response.data);
  } catch (error) {
    console.error('Error disabling gift cards:', error);
    throw error;
  }
};

export {
  assignMerchStoreMemberGiftCards,
  getMerchStoreGiftCardsStatus,
  getMemberGiftCards,
  disableMemberGiftCards,
  reassignMerchStoreMemberGiftCard,
  unassignMerchStoreMemberGiftCards,
};
