import { useState } from 'react';

import { getOrdersList } from '@merchstores/admin/components/MerchStoreOrders/OrdersList';
import { Loading } from '@merchstores/shared/components/Loading';
import { IMerchStoreIndividualOrder } from '@merchstores/admin/types/merchstoreOrder';

async function getOrders({
  month,
  year,
}: {
  month: string | null;
  year: string | null;
}) {
  // Helper to convert month name to number
  const monthToNumber = (month: string | null): number | null => {
    if (!month) return null;

    const months: Record<string, number> = {
      january: 1,
      february: 2,
      march: 3,
      april: 4,
      may: 5,
      june: 6,
      july: 7,
      august: 8,
      september: 9,
      october: 10,
      november: 11,
      december: 12,
    };

    return months[month.toLowerCase()] || null;
  };

  const monthNumber = monthToNumber(month);
  const yearNumber = year ? parseInt(year, 10) : null;

  const batchSize = 1000;
  let skip = 0;
  let orders: IMerchStoreIndividualOrder[] = [];
  let hasMoreData = true;

  while (hasMoreData) {
    const ordersResp = await getOrdersList({
      month: monthNumber,
      year: yearNumber,
      skip,
      limit: batchSize,
    });

    const newOrders = ordersResp.ordersList;

    orders = orders.concat(newOrders);

    if (newOrders.length < batchSize) {
      hasMoreData = false;
    } else {
      skip += batchSize;
    }
  }

  return orders;
}

export default function OrdersCSVDownloadMenu({
  className,
}: {
  className?: string;
  storeCode?: string | undefined | null;
}): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const date = new Date();
  const startYear = 2021;
  const currentYear = date.getFullYear();
  date.setMonth(date.getMonth() - 1);
  const defaultMonth = date.toLocaleString('default', { month: 'long' });
  const defaultYear = date.getFullYear();
  const [month, setMonth] = useState(defaultMonth);
  const [year, setYear] = useState(defaultYear);

  function createAndDownloadCSV({
    orders,
  }: {
    orders: IMerchStoreIndividualOrder[];
  }) {
    const headerKeySet: Set<string> = new Set();
    orders.forEach((row: any) => {
      Object.keys(row).forEach((key) => headerKeySet.add(key));
    });
    const headers = Array.from(headerKeySet);
    const rows = orders.map((row: Partial<IMerchStoreIndividualOrder>) =>
      Object.values(row).map((value) => value || '')
    );
    const csvRows = [] as string[];
    csvRows.push(headers.join(','));
    csvRows.push(...rows.map((row) => row.join(',')));
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `orders-export-${month}-${year}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  async function onDownloadClick(): Promise<void> {
    setIsLoading(true);
    try {
      const orders = await getOrders({ month, year });
      createAndDownloadCSV({ orders });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className={`flex lg:flex-row flex-col  ${className}`}>
      <select
        className="lg:mr-2 lg:mb-0 mb-2 p-2 border rounded"
        defaultValue={defaultMonth}
        onChange={(e) => setMonth(e.target.value)}
      >
        <option value="">Select Month</option>
        <option value="January">January</option>
        <option value="February">February</option>
        <option value="March">March</option>
        <option value="April">April</option>
        <option value="May">May</option>
        <option value="June">June</option>
        <option value="July">July</option>
        <option value="August">August</option>
        <option value="September">September</option>
        <option value="October">October</option>
        <option value="November">November</option>
        <option value="December">December</option>
      </select>
      <select
        className="lg:mr-2 lg:mb-0 mb-2 p-2 border rounded"
        defaultValue={defaultYear}
        onChange={(e) => setYear(e.target.value)}
      >
        <option value="">Select Year</option>
        {Array.from({ length: currentYear - startYear + 1 }, (_, index) => (
          <option key={index} value={currentYear - index}>
            {currentYear - index}
          </option>
        ))}
      </select>
      <button
        className={`btn secondary w-72`}
        onClick={onDownloadClick}
        disabled={isLoading}
      >
        {isLoading ? (
          <Loading isLoading={isLoading} width={'24'} />
        ) : (
          'Download CSV'
        )}
      </button>
    </div>
  );
}
